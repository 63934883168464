<template>
  <el-card >
    <div class = "flexRow" style="justify-content: space-between;">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class ="keyfont">修改内容：</div>
        <el-select v-model="content" placeholder="请选择修改内容" clearable>
          <el-option v-for="(v,i) in contentlist" :key="i" :value="v.value" :label="v.label"></el-option>
        </el-select>
        <el-button type="primary" style="margin-left:15px" @click="query">查询</el-button>
        <el-button type="primary" @click="batchshow = true">批量修改</el-button>
      </div>
      <div>
        <!-- <el-link type="info" href="https://jusnn6k8al.feishu.cn/docs/doccnLEuCXwTf4M8qnWPIhXYP0d" style="color:#666666" target="_blank">
          <i class = "el-icon-document"></i>
          批量改商品操作说明
        </el-link> -->
      </div>
    </div>
    <el-table :data="tablelist" style="margin-top:10px" v-loading="loading">
      <el-table-column prop="AddTime" label="操作时间"></el-table-column>
      <el-table-column prop="Operator" label="操作人"></el-table-column>
      <el-table-column prop="ImportProductEditTypeValue" label="修改内容"></el-table-column>
      <el-table-column prop="ProductCount" label="商品总数"></el-table-column>
      <el-table-column prop="SuccessProductCount" label="成功导入数量" ></el-table-column>
      <el-table-column prop="ImportRecordStateValue" label="状态">
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <div v-if="scope.row.ImportRecordState == 1" class="statefont" style="color:#409eff;cursor:pointer" @click="download(scope.row)">
            下载失败数据
          </div>
          <div v-else-if="scope.row.ImportRecordState == 0" class="statefont">
            报表生成中
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="width:100%;text-align:right;margin-top:10px"
      v-if="tablelist != undefined && tablelist.length > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentpage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog :visible.sync="batchshow" title="批量修改商品" width="600px" top="38vh" @closed="batchradio=1">
      <div class = "flexRow" style="align-items:flex-start">
        <div style="margin:0px 30px 0px 15px">批量修改</div>
        <div>
          <el-radio-group v-model="batchradio">
            <div class = "radioopt">
              <el-radio :label="1">
                <span>商品价格</span>
              </el-radio>
              <div class = "radioopt-font">根据商品规格，批量修改商品规格售价</div>
            </div>
            <div class = "radioopt">
              <el-radio :label="2">
                <span>收益或提成</span>
              </el-radio>
              <div class = "radioopt-font">根据商品，修改推手收益或员工提成</div>
            </div>
          </el-radio-group>
        </div>
      </div>
      <div style="margin-top:30px;text-align:right">
        <el-button type="primary" @click="choosebatchradio">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量修改商品价格" :visible.sync="exportchange">
      <div style="display:flex;flex-direction:row;margin-left:30px">
        <div style="font-size:14px;line-height:20px">上传数据：</div>
        <input type="file" ref="upFiles" id="inputsId" />
      </div>
      <div style="margin-left:30px;font-size:14px;margin-top:20px">
        <p>操作提示:</p>
        <p>
          1.请按照模板要求进行改价,<span
            style="color:#409eff;cursor:pointer"
            @click="downLoadsend"
            >点击下载批量修改商品模板</span
          >
        </p>
        <p>该功能支持商城内已有商品的售价、成本价、划线价进行更改</p>
        <p>2.单次导入最多支持3000行数据,如超过部分请分多次操作</p>
        <p>3.若出现导入失败的商品,可下载失败数据查看具体原因</p>
      </div>
      <div style="width:100%;text-align:center;margin-top:20px">
        <el-button
          type="primary"
          @click="theimport"
          :disabled="upfileloading"
          :loading="upfileloading"
          >确定导入</el-button
        >
      </div>
    </el-dialog>

    <el-dialog :visible.sync="earningsshow" title="批量修改收益或提成" @close="closeearings" v-loading = "earningsloading">
      <div class = "bluebox">
        <div>操作提示：</div>
        <div>1.请按照模板内的提示进行修改</div>
        <div>2.该功能仅支持修改推手收益和员工提成</div>
        <div>3.单次导入最多支持3000行数据，如超过请分多次操作</div>
        <div>4.若出现导入失败的商品，可下载失败数据查看具体原因</div>
      </div>
      <div class = "flexRow" style="align-items:flex-start;margin-top:20px;">
        <div style="margin:0px 10px 0px 0px;white-space:nowrap;">下载模板：</div>
        <div>
          <el-radio-group v-model="templatestate">
            <div class = "radioopt">
              <el-radio :label="1">
                <span>空白模板</span>
                <el-button v-if="templatestate==1" type="text" style="margin:0px 0px 0px 15px;padding:0px" @click="clickdown">点击下载</el-button>
              </el-radio>
              <div class = "radioopt-font">操作步骤：点击下载模板文件→在文件中录入商品信息、修改后的收益或提成→导入文件批量修改</div>
            </div>
            <div class = "radioopt">
              <el-radio :label="2">
                <span>活动商品模板</span>
                <el-button v-if="templatestate==2" type="text" style="margin:0px 0px 0px 15px;padding:0px" @click="clickdown">点击下载</el-button>
              </el-radio>
              <div>
                <div class = "radioopt-font">操作步骤：选择营销活动→导出活动商品→修改活动商品的收益或提成→导入文件批量修改</div>
                <div class = "flexRow" style="margin:5px 0px 5px 23px" v-if="templatestate==2" v-loading = "activeloading">
                  <el-select v-model="activityclass" placeholder="选择活动类型" clearable @change="changeactive">
                    <el-option v-for="(v,i) in activityclasslist" :key="i" :value="v.value" :label="v.label"></el-option>
                  </el-select>
                  <el-select v-model="activityscreen" placeholder="输入活动名称选择活动" clearable filterable style="margin-left:10px"
                   :no-data-text="activityclass?'无数据':'请先选择活动类型'">
                    <div v-if="activityclass">
                      <el-option v-for="(v,i) in activityscreenlist" :key="i" :value="v.value" :label="v.label"></el-option>
                    </div>
                  </el-select>
                </div>
              </div>
            </div>
          </el-radio-group>
        </div>
      </div>
      <div class = "flexRow" style="align-items:flex-start">
        <div style="margin:10px 10px 0px 0px;white-space:nowrap;">上传数据：</div>
        <div class = "flexRow" style="align-items:center">
          <el-upload :auto-upload="false" :action="fileApi" :before-upload = "beforeupload" accept=".xls,.xlt,.xlsx,.xlsm,.xltx,.xltm"
          :on-remove = "onremove" ref="uploadFile" :on-change="fileChange" :file-list="fileList" list-type="text" style="margin-top:5px">
            <el-button size="mini">选择文件</el-button>
          </el-upload>
          <span v-if="!fileList||!fileList.length" style="font-size:14px;margin-left:10px;">未选择任何文件</span>
        </div>
      </div>
      <div style="margin-top:20px;text-align:right">
        <el-button @click="closeearings">取消</el-button>
        <el-button type="primary" @click="confirmexport">确定导入</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import axios from "axios";
import config from "@/config/index";
import { 
  changepricelist,
  timelimitdiscountlist,
  activityBaleList,
  producteditpriceimport
 } from "@/api/goods";
 import apiList from '@/api/other'
export default {
  data() {
    return {
      loading: false,
      upfileloading: false,
      exportchange: false,
      tablelist: [],

      state: true, //报表生成

      thefile: null,

      currentpage: 1,
      pagesize: 20,
      total: null,

      exportUrl: config.EXPORT_URL,
      fileApi: config.UPLOAD_FILE,
      content:null,
      contentlist:[
        {label:'商品价格',value:0},
        {label:'收益或提成',value:1},
      ],

      batchshow:false,
      batchradio:1,

      earningsshow:false,
      earningsloading:false,
      templatestate:1,
      activityclass:null,
      activityclasslist:[
        {label:'限时折扣',value:1},
        {label:'满减送',value:2},
        {label:'打包一口价',value:3},
      ],
      activityscreen:null,
      activityscreenlist:[],
      activeloading:false,
      fileList:[],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    changeactive(){
      this.activityscreen = null
      this.activityscreenlist = []
      if(this.activityclass==1){
        this.gettimelimitdiscountlist()
      }else if(this.activityclass==2){
        this.getactivityMarkdownGiftList()
      }else if(this.activityclass==3){
        this.getactivityBaleList()
      }
    },
    //限时折扣
    async gettimelimitdiscountlist(){
      this.activeloading = true
      try{
        let data = {
          ActivityState:null,
          Skip: 0,
          Take: 9999
        }
        let result = await timelimitdiscountlist(data)
        if(result.IsSuccess){
          result.Result.Results.map((v)=>{
            if(v.ActivityStateValue!='已结束'){
              let data = {
                value:v.ActivityFullId,
                label:v.Name
              }
              this.activityscreenlist.push(data)
            }
          })
        }
      }finally{
        this.activeloading = false
      }
    },
    //满减送
    async getactivityMarkdownGiftList(){
      this.activeloading = true
      try{
        let data = {
          ConditionType:null,
          State:null,
          Skip: 0,
          Take: 9999
        }
        let result = await apiList.activityMarkdownGiftList(data)
        if(result.IsSuccess){
          result.Result.Results.map((v)=>{
            if(v.State!=3){
              let data = {
                value:v.Id,
                label:v.Name
              }
              this.activityscreenlist.push(data)
            }
          })
        }
      }finally{
        this.activeloading = false
      }
    },
    //打包一口价
    async getactivityBaleList(){
      this.activeloading = true
      try{
        let data = {
          ActivityState:null,
          Skip: 0,
          Take: 9999
        }
        let result = await activityBaleList(data)
        if(result.IsSuccess){
          result.Result.Results.map((v)=>{
            if(v.ActivityState!=2){
              let data = {
                value:v.Id,
                label:v.Name
              }
              this.activityscreenlist.push(data)
            }
          })
        }
      }finally{
        this.activeloading = false
      }
    },
    //下载模板
    clickdown(){
      let ActivityId
      if(this.templatestate==1){
        ActivityId = 0
      }else{
        if(!this.activityscreen || !this.activityclass){
          this.$message.error('请选择营销活动')
          return false
        }else{
          ActivityId = this.activityscreen
        }
      }
      let url = this.exportUrl + '/pc/product/producteditincomeorcommissiontempleteexport?ActivityId='+ActivityId
      window.open(url)
    },
    confirmexport(){
      if(!this.fileList||!this.fileList.length){
        this.$message.error('请选择导入文件')
      }
      // else if(this.templatestate==2&&(!this.activityclass||!this.activityscreen)){
      //   this.$message.error('请选择营销活动')
      // }
      else{
        this.toconfirmexport()
      }
    },
    async toconfirmexport(){
      this.earningsloading = true
      try{
        let formData = new FormData();
        formData.append("file", this.fileList[0]);
        formData.append('ImportProductEditType',1)
        let result = await axios({
          url: this.exportUrl + "/pc/product/producteditpriceimport",
          method: "post",
          headers: {
            "Content-type": "multipart/form-data",
          },
          data: formData,
        });
        if (result.data.IsSuccess) {
          this.$message.success(result.data.Message);
          this.earningsshow = false
          this.getlist()
        } else {
          this.$message(result.data.Message);
        }
        console.log(result)
      }finally{
        this.earningsloading = false
      }
    },
    closeearings(){
      this.earningsshow = false
      setTimeout(()=>{
        this.templatestate = 1
        this.activityclass = null
        this.activityscreen = null
        this.fileList = []
      },100)
    },
    onremove(file, fileList){
      // console.log(fileList)
      this.fileList = fileList
    },
    beforeupload(file){
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message({
          showClose: true,
          type: "error",
          message: "上传文件只能为excel文件，且为xlsx,xls格式",
        });
        this.file = "";
        return false;
      }
    },
    fileChange(file, fileList) {
      // console.log(file)
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.fileList=[file.raw]
      // console.log(this.fileList)
    },
    choosebatchradio(){
      if(this.batchradio==1){
        this.exportchange = true
      }else{
        this.earningsshow = true
      }
      this.batchshow = false
    },
    query(){
      this.currentpage = 1
      this.getlist()
    },
    async getlist() {
      this.loading = true;
      let data = {
        ImportProductEditType:this.content,
        Skip: (this.currentpage - 1) * this.pagesize,
        Take: this.pagesize,
      };
      let result = await changepricelist(data);
      if (result.IsSuccess) {
        this.tablelist = result.Result.Results;
        this.total = result.Result.Total;
        // console.log(result.Result.Results);
      }
      this.loading = false;
    },
    //每页数量
    handleSizeChange(e) {
      this.currentpage = 1;
      this.pagesize = e;
      this.getlist();
    },
    //页码
    handleCurrentChange(e) {
      this.currentpage = e;
      this.getlist();
    },
    //下载失败数据
    download(e) {
      this.getfile(e.Id);
    },
    async getfile(e) {
      let id = await e;
      window.open(
        `${this.exportUrl}/pc/product/producteditpricerecordexport?Id=${id}`
      );
    },
    //下载模板
    downLoadsend() {
      // let url = this.exportUrl + "/pc/product/producteditpricetempleteexport";
      window.open('http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/批量修改价格导入模板.xlsx');
    },
    //确定导入
    theimport() {
      // console.log(this.$refs.upFiles.value)

      // console.log(this.$refs.upFiles.files[0]);
      if (!this.$refs.upFiles.files) {
        this.$message.error("请选择导入的文件");
      } else {
        this.importfile();
      }
      // console.log('确定导入')
    },
    //导入
    async importfile() {
      this.upfileloading = true;
      let formData = new FormData();
      formData.append("file", this.$refs.upFiles.files[0]);
      formData.append('ImportProductEditType',0)
      try {
        let result = await axios({
          url: this.exportUrl + "/pc/product/producteditpriceimport",
          method: "post",
          headers: {
            "Content-type": "multipart/form-data",
          },
          data: formData,
        });
        if (result.data.IsSuccess) {
          this.$message.success(result.data.Message);
          this.earningsshow = false
          this.getlist()
        } else {
          this.$message(result.data.Message);
        }
      } finally {
        this.upfileloading = false;
      }
    },
  },
};
</script>

<style lang = "less" scoped>
.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.keyfont{
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  color: #606266;
}
.statefont {
  font-size: 14px;
}
.radioopt{
  padding:0px 5px 15px 5px;
  .radioopt-font{
    margin-left:23px;
    font-size: 12px;
    color: #999999;
    line-height:1.5
  }
}
.bluebox{
  border:1px solid #409eff;
  background: #fdfcfc;
  padding:10px;
  div{
    font-size: 14px;
    color: #999999;
    line-height:1.5
  }
}
.grayfont{
  font-size: 12px;
  color: #999999;
  line-height:1.5
}
</style>
